<template>
  <!-- 评审会批复 -->
  <div class="decisionMake">
    <backtitle lable="融资决议管理"></backtitle>
    <!-- 补充材料制作 -->
    <supplement-make v-if="type==='1'" :gysData='gysData' :providertData='providertData'/>
    <!-- 批复文件制作 -->
    <reply-make v-if="type==='2'" :gysData='gysData'  :providertData='providertData' />
  </div>
</template>

<script>
import {
  financingConfig,
  programmeConfig,
  fileConfig,
  problemConfig,
  measuresConfig
} from './utils/config'
import SupplementMake from './components/supplement-make.vue'
import ReplyMake from './components/reply-make.vue'
import Backtitle from '../../components/backtitle.vue'
import Storage from '@/utils/storage'
import { supplierApi } from '@/api/companyApi'
import { parameterApi } from '@/api/parameterApi'
export default {
  components: {
    Backtitle,
    SupplementMake,
    ReplyMake
  },
  props: {},
  data () {
    return {
      type: '',
      textarea: '',
      fileData: {},
      generateFileId: '',
      financingData: {},
      programmeData: { validTimeUnit: '' },
      problemData: [],
      measuresData: [],
      gysData: [],
      providertData: []
    }
  },
  // 计算属性 类似于data概念
  computed: {
    problemConfig () {
      return problemConfig(this)
    },
    measuresConfig () {
      return measuresConfig(this)
    },
    fileConfig () {
      return fileConfig(this)
    },
    financingConfig () {
      return financingConfig(this)
    },
    programmeConfig () {
      return programmeConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取资金方信息
    providertInfo () {
      const params = {
        replyStatus: 1 // 审核状态
      }
      parameterApi.getFundsprovidertInfo(params)
        .then(res => {
          if (res.data) {
            this.providertData = res.data

            this.providertData.forEach(ele => {
              this.$set(ele, 'capitalAndProductName', ele.capitalSideName + '-' + ele.productName)
            })
          }
        })
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.type = Storage.getLocal('contractInfo').type
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.gysList()
    this.providertInfo()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>

</style>
